export const COLORS = {
  BLACK: "#161616",
  WHITE: "#fff",
  LIGHT_GRAY: "#606060",
  MID_GRAY: "#444444",
  DARK_GRAY: "#282828",
  MID_ORANGE: "#DD8833",
  DARK_ORANGE: "#884400",
  MID_YELLOW: "#CC8833",
  DARK_YELLOW: "#996600",
  LIGHT_RED: "#FF5555",
  MID_RED: "#DD3333",
  DARK_RED: "#AA1111",
  MID_GREEN: "#33DD88",
  DARK_GREEN: "#008844",
  MID_PURPLE: "#8833DD",
  DARK_PURPLE: "#440088",
  ERROR_RED: "#FF0000",
};

export const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
  // Remove the hash if present
  const cleanHex = hex.replace(/^#/, "");

  // Parse the hex values for red, green, and blue
  const bigint = parseInt(cleanHex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // Check if the input is a valid hex color
  if (isNaN(r) || isNaN(g) || isNaN(b)) {
    throw new Error("Invalid HEX color");
  }

  return { r, g, b };
};

export const hexToRgbWithAlpha = (hex: string, alpha: number): string => {
  const { r, g, b } = hexToRgb(hex);
  return `rgb(${r}, ${g}, ${b}, ${alpha})`;
};
