import { MutableRefObject, RefObject, useContext, useRef } from "react";
import { InterestFormContext } from "../InterestForm";
import { InterestFormState } from "../../interestFormTypes";
import React from "react";
import {
  OptionsContainerColumn,
  OptionsContainerRow,
  InterestFormTableTitle,
  InterestFormCheckbox,
} from "../../interestFormComponents";
import BackAndNext from "../BackAndNext";
import StepTrainingFrequency from "./StepTrainingFrequency";
import StepTrainingWeekdays from "./StepTrainingWeekdays";

type Loc = "TS" | "UD";
type Day = "saturday" | "sunday";
type Time = "morning" | "earlyAfternoon" | "lateAfternoon" | "evening";

const StepTrainingTimePlace = () => {
  const { answers, answerStep } = useContext(InterestFormContext);

  const checkboxRefs: MutableRefObject<RefObject<HTMLInputElement>[]> = useRef(
    Array.from({ length: 16 }, () => React.createRef())
  );

  const onNext = () => {
    const checkboxStates = checkboxRefs.current.map(
      (ref) => ref.current?.checked || false
    );

    answerStep(
      {
        availabilityWeekend: {
          TS: {
            saturday: {
              morning: checkboxStates[0],
              earlyAfternoon: checkboxStates[1],
              lateAfternoon: checkboxStates[2],
              evening: checkboxStates[3],
            },
            sunday: {
              morning: checkboxStates[4],
              earlyAfternoon: checkboxStates[5],
              lateAfternoon: checkboxStates[6],
              evening: checkboxStates[7],
            },
          },
          UD: {
            saturday: {
              morning: checkboxStates[8],
              earlyAfternoon: checkboxStates[9],
              lateAfternoon: checkboxStates[10],
              evening: checkboxStates[11],
            },
            sunday: {
              morning: checkboxStates[12],
              earlyAfternoon: checkboxStates[13],
              lateAfternoon: checkboxStates[14],
              evening: checkboxStates[15],
            },
          },
        },
      } as InterestFormState,
      checkboxStates.find((e) => e)
        ? StepTrainingFrequency
        : StepTrainingWeekdays
    );
  };

  return (
    <div>
      <center>Quando sei disponibile per gli allenamenti?</center>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <OptionsContainerRow>
          <InterestFormTableTitle>&nbsp;</InterestFormTableTitle>
          <InterestFormTableTitle style={{ flexBasis: `${(2 / 5) * 100}%` }}>
            Trieste
          </InterestFormTableTitle>
          <InterestFormTableTitle style={{ flexBasis: `${(2 / 5) * 100}%` }}>
            Udine
          </InterestFormTableTitle>
        </OptionsContainerRow>
        <OptionsContainerRow>
          <InterestFormTableTitle>&nbsp;</InterestFormTableTitle>
          <InterestFormTableTitle>Sabato</InterestFormTableTitle>
          <InterestFormTableTitle>Domenica</InterestFormTableTitle>
          <InterestFormTableTitle>Sabato</InterestFormTableTitle>
          <InterestFormTableTitle>Domenica</InterestFormTableTitle>
        </OptionsContainerRow>
        <OptionsContainerRow>
          <OptionsContainerColumn style={{ border: 0 }}>
            <span>10:00 - 13:00</span>
            <span>13:00 - 16:00</span>
            <span>16:00 - 19:00</span>
            <span>19:00 - 22:00</span>
          </OptionsContainerColumn>

          {["TS", "UD"].map((loc, locIndex) =>
            ["saturday", "sunday"].map((day, dayIndex) => (
              <OptionsContainerColumn key={`${loc}-${day}`}>
                {["morning", "earlyAfternoon", "lateAfternoon", "evening"].map(
                  (time, timeIndex) => {
                    const index = locIndex * 8 + dayIndex * 4 + timeIndex;
                    return (
                      <InterestFormCheckbox
                        bigger
                        key={`${loc}-${day}-${time}`}
                        ref={checkboxRefs.current[index]}
                        defaultChecked={
                          answers?.availabilityWeekend?.[loc as Loc]?.[
                            day as Day
                          ]?.[time as Time]
                        }
                      />
                    );
                  }
                )}
              </OptionsContainerColumn>
            ))
          )}
        </OptionsContainerRow>
      </div>
      <BackAndNext onNext={onNext} />
    </div>
  );
};

export default StepTrainingTimePlace;
