import React from "react";
import { RouterProvider } from "react-router-dom";
import { generateRouter } from "./router/routerConfig";
import { Provider } from "react-redux";
import store from "./redux/store";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

const App = () => {
  return (
    <>
      <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
          <RouterProvider router={generateRouter()} />
        </DndProvider>
      </Provider>
    </>
  );
};

export default App;
