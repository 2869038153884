import styled from "styled-components";
import { COLORS } from "../../common/styles/colors";

const StyledLink = styled.a`
  color: ${COLORS.MID_ORANGE};
  font-weight: bold;

  &:hover {
    color: ${COLORS.DARK_ORANGE};
  }

  &:active {
    color: ${COLORS.WHITE};
  }
`;

const AccentTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  border: 1px solid ${COLORS.MID_ORANGE};
  td,
  th {
    padding: 0.5rem;
  }
  thead {
    background-color: ${COLORS.MID_ORANGE};
    color: ${COLORS.WHITE};
  }
  tbody tr:nth-child(odd) {
    background-color: ${COLORS.LIGHT_GRAY};
  }
  tbody tr:nth-child(even) {
    background-color: ${COLORS.MID_GRAY};
  }
`;

const CookiePolicyPage = () => (
  <div>
    <h1>Informativa sui Cookie</h1>

    <h2>Cosa sono i cookie?</h2>
    <p>
      I cookie sono piccoli file di testo che vengono memorizzati sul tuo
      dispositivo quando visiti un sito web. Sono utilizzati per garantire il
      corretto funzionamento del sito, migliorare l'esperienza dell'utente,
      analizzare il traffico e fornire contenuti personalizzati.
    </p>

    <h2>Quali cookie utilizziamo?</h2>

    <h3>1. Cookie essenziali (necessari)</h3>
    <p>
      Questi cookie sono fondamentali per il funzionamento del nostro sito web e
      non possono essere disabilitati nei nostri sistemi. Sono generalmente
      impostati solo in risposta a azioni effettuate dall'utente, come
      l'impostazione delle preferenze sulla privacy, l'accesso o la compilazione
      di moduli.
    </p>
    <AccentTable>
      <thead>
        <tr>
          <th>Nome del Cookie</th>
          <th>Fornitore</th>
          <th>Scopo</th>
          <th>Durata</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>laravel_session</td>
          <td>Nostro sito</td>
          <td>Mantiene la sessione attiva per gli utenti autenticati</td>
          <td>Sessione</td>
        </tr>
      </tbody>
    </AccentTable>

    <h3>2. Cookie di terze parti (Vimeo)</h3>
    <p>
      Il nostro sito incorpora video ospitati su Vimeo. Quando visualizzi questi
      video, Vimeo può impostare diversi cookie sul tuo dispositivo. Questi
      cookie possono essere utilizzati per tracciare l'interazione con i video e
      memorizzare le preferenze dell'utente.
    </p>
    <AccentTable>
      <thead>
        <tr>
          <th>Nome del Cookie</th>
          <th>Fornitore</th>
          <th>Scopo</th>
          <th>Durata</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>_cfuvid</td>
          <td>Vimeo</td>
          <td>Utilizzato da Cloudflare per identificare clienti fidati</td>
          <td>Sessione</td>
        </tr>
        <tr>
          <td>auth_redirect</td>
          <td>Vimeo</td>
          <td>Gestisce i reindirizzamenti dopo l'autenticazione</td>
          <td>Sessione</td>
        </tr>
        <tr>
          <td>cf_clearance</td>
          <td>Vimeo</td>
          <td>Gestisce le sfide di sicurezza di Cloudflare</td>
          <td>12 mesi</td>
        </tr>
        <tr>
          <td>has_logged_in</td>
          <td>Vimeo</td>
          <td>Indica se l'utente ha effettuato l'accesso</td>
          <td>12 mesi</td>
        </tr>
        <tr>
          <td>has_uploaded</td>
          <td>Vimeo</td>
          <td>Indica se l'utente ha caricato contenuti</td>
          <td>12 mesi</td>
        </tr>
        <tr>
          <td>is_logged_in</td>
          <td>Vimeo</td>
          <td>Verifica lo stato di accesso dell'utente</td>
          <td>12 mesi</td>
        </tr>
        <tr>
          <td>language</td>
          <td>Vimeo</td>
          <td>Memorizza le preferenze linguistiche dell'utente</td>
          <td>12 mesi</td>
        </tr>
        <tr>
          <td>player</td>
          <td>Vimeo</td>
          <td>Salva le preferenze del lettore video</td>
          <td>12 mesi</td>
        </tr>
        <tr>
          <td>redirect_url_after_social_login</td>
          <td>Vimeo</td>
          <td>Gestisce l'URL di reindirizzamento dopo il login sociale</td>
          <td>Sessione</td>
        </tr>
        <tr>
          <td>vimeo</td>
          <td>Vimeo</td>
          <td>Cookie funzionale per l'integrazione del video</td>
          <td>12 mesi</td>
        </tr>
        <tr>
          <td>vimeo_gdpr_optin</td>
          <td>Vimeo</td>
          <td>Memorizza le preferenze di consenso GDPR</td>
          <td>12 mesi</td>
        </tr>
        <tr>
          <td>vuid</td>
          <td>Vimeo</td>
          <td>
            Raccoglie dati analitici sul comportamento dell'utente sui video
          </td>
          <td>2 anni</td>
        </tr>
      </tbody>
    </AccentTable>

    <h2>Gestione dei Cookie</h2>
    <p>
      Puoi controllare e gestire i cookie attraverso le impostazioni del tuo
      browser. La maggior parte dei browser ti consente di rifiutare o accettare
      i cookie, di eliminare i cookie esistenti e di impostare preferenze per
      determinati siti web. Tieni presente che la disabilitazione dei cookie
      potrebbe influire sulla funzionalità del nostro sito web.
    </p>
    <p>
      Per ulteriori informazioni su come gestire i cookie, consulta la
      documentazione del tuo browser o visita le seguenti pagine:
    </p>
    <ul>
      <li>
        <StyledLink
          href="https://support.google.com/chrome/answer/95647?hl=it"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Chrome
        </StyledLink>
      </li>
      <li>
        <StyledLink
          href="https://support.mozilla.org/it/kb/Eliminare%20i%20cookie"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mozilla Firefox
        </StyledLink>
      </li>
      <li>
        <StyledLink
          href="https://support.apple.com/it-it/HT201265"
          target="_blank"
          rel="noopener noreferrer"
        >
          Safari
        </StyledLink>
      </li>
      <li>
        <StyledLink
          href="https://support.microsoft.com/it-it/microsoft-edge/eliminare-i-cookie-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
          target="_blank"
          rel="noopener noreferrer"
        >
          Microsoft Edge
        </StyledLink>
      </li>
    </ul>

    <h2>Modifiche alla nostra politica sui cookie</h2>
    <p>
      Ci riserviamo il diritto di aggiornare questa informativa in qualsiasi
      momento. Ti invitiamo a controllare periodicamente questa pagina per
      essere informato su eventuali modifiche.
    </p>
    <p>
      Se hai domande o richieste riguardo l'uso dei cookie, puoi contattarci a{" "}
      <StyledLink href="mailto:play@quadball.fvg.it">
        play@quadball.fvg.it
      </StyledLink>
      .
    </p>
  </div>
);

export default CookiePolicyPage;
