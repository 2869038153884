import React, { useEffect } from "react";
import CardContainerWithVideo from "./components/CardContainerWithVideo";
import { useDispatch } from "react-redux";
import { homePageCardsRequest } from "./slices/homePageSlice";

const HomePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(homePageCardsRequest());
  }, [dispatch]);

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <CardContainerWithVideo />
      </div>
      <div
        style={{
          width: "75%",
          margin: "0 auto",
          textAlign: "center",
          fontFamily: "Courier New, serif",
          fontSize: "1.2em",
        }}
      >
        <h4>
          Siamo una squadra in fase di formazione che promuove il{" "}
          <b>quadball</b> in <b>Friuli-Venezia Giulia</b>, uno sport misto
          ispirato al Quidditch di Harry Potter. Il <b>quadball</b> combina
          elementi di <em>pallamano</em>, <em>rugby</em>, <em>dodgeball</em>,{" "}
          <em>arti marziali</em> e altri sport, creando un'esperienza dinamica e
          coinvolgente.
        </h4>

        <h4>
          Le partite si giocano con <b>7 atleti per squadra</b> in campo, ma
          ogni squadra può avere fino a <b>21 atleti</b> che si alternano,
          proprio come nell'hockey su ghiaccio. Ogni squadra è composta da
          atleti di <b>generi misti</b>, secondo il regolamento, rendendo il
          gioco inclusivo e divertente per tutti.
        </h4>

        <h4>
          Accogliamo con entusiasmo chiunque voglia unirsi a noi in questa
          avventura. Se sei interessato, non esitare a contattarci!
        </h4>
      </div>
    </>
  );
};

export default HomePage;
