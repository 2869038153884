import { useContext } from "react";
import { InterestFormContext } from "../InterestForm";
import { InterestFormState } from "../../interestFormTypes";
import { BigButton, JustifyOrCenter } from "../../interestFormComponents";
import { AccentLink } from "../../../../common/components/links/styledLinks";
import { routes } from "../../../../common/constants/appRoutes";
import StepContacts from "./StepContacts";
import StepInfo from "./StepInfo";
import VideoPlayer from "../../../home/components/VideoPlayer";

const StepStart = () => {
  const { answerStep, handleNo } = useContext(InterestFormContext);

  return (
    <>
      <JustifyOrCenter>
        <h4>
          Nato come quidditch, il quadball è iniziato da un'idea di fantasia ma
          dal 2005 è uno sport reale e coinvolgente. Scopri di più su come si
          gioca, e come unirti a noi per (ri)portarlo in Friuli-Venezia Giulia!
        </h4>
      </JustifyOrCenter>
      <div style={{ position: "relative", display: "flex", flexGrow: 1 }}>
        <VideoPlayer videoHeight="auto" orientationModifier={1} />
      </div>
      <center style={{ fontWeight: "bold" }}>Vuoi giocare con noi?</center>
      <BigButton
        onClick={() =>
          answerStep({ needMoreInfo: false } as InterestFormState, StepContacts)
        }
      >
        Sì
      </BigButton>
      <BigButton
        onClick={() =>
          answerStep({ needMoreInfo: true } as InterestFormState, StepInfo)
        }
      >
        Vorrei saperne di più
      </BigButton>
      <BigButton
        onClick={() => {
          answerStep({} as InterestFormState);
          handleNo();
        }}
      >
        No grazie
      </BigButton>
      <center>
        <AccentLink
          href={routes.INTEREST_FORM.INTEREST_FORM_PRIVACY.path}
          target="_blank"
        >
          Informativa sulla privacy
        </AccentLink>
      </center>
    </>
  );
};

export default StepStart;
