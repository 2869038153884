import { useContext } from "react";
import { InterestFormContext } from "../InterestForm";
import { InterestFormState } from "../../interestFormTypes";
import { BigButton } from "../../interestFormComponents";
import Accordion, { Section } from "../../../../common/components/Accordion";
import styled, { css } from "styled-components";
import { AccentLink } from "../../../../common/components/links/styledLinks";
import StepContacts from "./StepContacts";
import CreditsLabel from "../../../../common/components/CreditsLabel";

const VideoFrame = styled.iframe`
  width: 100%;
  margin-top: 20px;
  border: 0;
`;

const StepInfo = () => {
  const { answerStep, handleNo } = useContext(InterestFormContext);

  return (
    <>
      <h4>
        Ecco alcune informazioni che abbiamo raccolto per chi si avvicina per la
        prima volta al nostro sport!
      </h4>
      <Accordion customStyles={{ 2: { padding: 0 } }}>
        <Section header="Domande frequenti">
          <h3>Quando e dove si svolgono gli allenamenti?</h3>
          <p>
            Gli allenamenti si svolgono una volta al mese nel fine settimana. Il
            luogo è attualmente variabile in base alla composizione della
            squadra; abbiamo iniziato a Udine, ma ci siamo di recente spostati a
            Ronchi dei Legionari. La composizione del gruppo potrebbe portare
            ulteriori modifiche sia alla frequenza che alla località scelta.
          </p>
          <img
            src="https://res.cloudinary.com/dfml8txza/image/upload/f_auto,q_auto/v1/quadballfvg/interest/rusgvloesocmgynygdyx"
            alt="Allenamento al Parco del Cormor a Udine"
          />
          <h5 style={{ textAlign: "right" }}>
            Allenamento al Parco del Cormor, Udine
          </h5>

          <h3>Come funzionano le competizioni?</h3>
          <p>
            Non esiste un campionato a causa del numero ridotto di squadre. Le
            competizioni si condensano nel corso di un fine settimana durante
            cui si giocano più partite.
            <br />
            Per la stagione 2025/26 vorremmo partecipare quanto meno a due
            tornei italiani: la Springbreak Cup e la Coppa Italiana Quadball. La
            prima è un torneo a carattere amichevole con la presenza di squadre
            straniere, mentre la seconda assegna il titolo nazionale.
            <br />
            Valuteremo anche la partecipazione alla Central European Quadball
            League, in cui giocano i "vicini di casa" di Ljubljana; questa si
            articola in eventi di una singola giornata con 3-4 squadre
            mitteleuropee.
          </p>
          <>
            <img
              src="https://res.cloudinary.com/dfml8txza/image/upload/f_auto,q_auto/v1/quadballfvg/interest/o4qehrublnp0tucvacya"
              alt="Torneo di quadball"
            />
            <CreditsLabel
              media="photo"
              position="relative"
              bottom={24}
              title="Van Klaveren Quidditch Photography"
              url="https://www.facebook.com/VanKlaverenQP"
              background={css`
                background: rgb(33, 33, 33, 0.5);
              `}
            />
          </>
          <h5 style={{ textAlign: "right" }}>
            Stand a un torneo internazionale europeo di quadball
          </h5>

          <h3>Quali sono i costi?</h3>
          <p>
            Gli allenamenti, per ora, sono gratuiti. Ci alleniamo in spazi
            pubblici e forniamo noi l'attrezzatura specifica necessaria. Una
            volta che dovessimo spostarci in strutture private sarà
            probabilmente necessario un contributo alle spese.
            <br />
            C'è una quota associativa di 30€ annui per poter partecipare agli
            eventi dell'Associazione Italiana Quidditch, ed un costo una tantum
            per ciascun torneo.
            <br />
            L'eventuale uniforme di gara sarà a carico dei giocatori, a meno di
            eventuali sponsorizzazioni.
          </p>

          <h3>Che tipo di impegno è richiesto?</h3>
          <p>
            Ad oggi l'asticella è abbastanza in basso. Vogliamo trovarci almeno
            una volta al mese, e partecipare a 2-3 tornei nel corso della
            stagione.
            <br />
            La nostra speranza nel giro di un anno è di riuscire almeno a
            raddoppiare la frequenza, perlomeno per la componente più
            competitiva della squadra. Le porte sono comunque aperte anche per
            chi è interessato a un'attività più moderata!
          </p>

          <h3>Chi può giocare?</h3>
          <p>
            Il quadball è uno sport inclusivo aperto a tutti, indipendentemente
            da genere ed esperienza sportiva! L'età è dai 16 anni compresi in
            su. Esiste una variante giovanile, ma non essendoci squadre
            giovanili in Italia per il momento non rientra nei nostri programmi.
          </p>

          <h3>Quale attrezzatura è necessaria?</h3>
          <p>
            Bastano abbigliamento sportivo e scarpe adatte al campo. Queste
            ultime sono solitamente scarpe da calcetto, calcio o rugby, con
            tacchetti in plastica o arrotondati in metallo. L'attrezzatura
            specifica del quadball viene fornita dalla squadra.
          </p>

          <h3>Quanto è impegnativo fisicamente il quadball?</h3>
          <p>
            Il Quadball è uno sport dinamico, e una certa preparazione atletica
            non può che aiutare. Esistono però ruoli adatti a diversi livelli di
            atletismo, o diversi approcci allo stesso ruolo.
          </p>

          <h3>È possibile fare una prova?</h3>
          <p>
            Sì! Attualmente tutti i nostri allenamenti sono aperti al pubblico.
          </p>

          <h3>Ci sono anche eventi sociali?</h3>
          <p>
            Sì! Per la bella stagione vi inviteremo a qualche gita in montagna
            assieme per conoscerci meglio.
            <br />
            Rimanendo nel quadball, ci sono una moltitudine di tornei
            amichevoli; in particolare i "fantasy" dove ci si iscrive
            individualmente e le squadre sono formate ad hoc. In questi, come
            nei tornei ufficiali, l'aspetto sociale è sempre presente.
          </p>
        </Section>
        <Section header="Scopri di più ">
          <center>
            <AccentLink href="/p/what-is-quadball" target="_blank">
              <h2>Cos'è il quadball?</h2>
            </AccentLink>
            <AccentLink href="/p/join-the-group" target="_blank">
              <h2>Unisciti al gruppo!</h2>
            </AccentLink>
          </center>
        </Section>
        <Section header="Video di introduzione al quadball">
          <VideoFrame
            width="560"
            height="315"
            src="https://www.youtube.com/embed/5eI4-i2aTRg"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></VideoFrame>
        </Section>
        <Section header="Sport misto?">
          <img
            src="https://res.cloudinary.com/dfml8txza/image/upload/f_auto,q_auto/v1/quadballfvg/interest/pxqb6pkkoxaiskjvympz"
            alt="Quadball, battitrice placca un battitore"
          />
          <p>
            Il quadball è misto per regolamento. La Maximum Gender Rule consente
            a una squadra di schierare al massimo 3 giocatori su 6 - o 4 su 7
            quando boccino e cercatori sono in campo - che si identifichino
            nello stesso genere.
            <br />
            In Italia esiste anche la <i>complementary rule</i>. Questa prevede
            un minimo di 2 giocatori - o 3 quando boccino e cercatori sono in
            campo - con lo stesso genere anagrafico. In caso di processo di
            rettifica del genere in atto, il giocatore può usare la
            documentazione che certifichi il processo in corso per essere
            considerato come appartenente al genere con cui si identifica.
            <br />
            In questo modo, ogni giocatore può scegliere il genere con cui
            identificarsi, ma si evita anche che una squadra possa schierare
            solo giocatori con lo stesso genere anagrafico, seppur con diversa
            identità di genere.
          </p>
          <p>
            Sicuramente il quadball non è perfetto, ed anche in questo aspetto
            potrebbe imparare ancora da altri sport di nicchia. Guardando però
            le migliori squadre internazionali, si può vedere come diversità e
            competitività possano andare di pari passo.
            <br />
            Se volete provare qualcosa di unico, difficilmente troverete altrove
            uno sport misto di contatto!
          </p>
        </Section>
      </Accordion>
      <center>
        <p>Ti abbiamo convinto?</p>
      </center>
      <BigButton
        onClick={() =>
          answerStep(
            { needMoreInfo: false, hasSeenInfoStep: true } as InterestFormState,
            StepContacts
          )
        }
      >
        Sì
      </BigButton>
      <BigButton
        onClick={() =>
          answerStep(
            { needMoreInfo: true, hasSeenInfoStep: true } as InterestFormState,
            StepContacts
          )
        }
      >
        Vorrei ancora qualche informazione
      </BigButton>
      <BigButton onClick={handleNo}>No</BigButton>
    </>
  );
};

export default StepInfo;
