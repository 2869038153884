import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../../common/styles/colors";
import FAIcon from "../../../common/components/FAIcon";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const StyledLink = styled(Link)`
  color: ${COLORS.MID_ORANGE};
  font-weight: bold;
  text-decoration: none;

  &:hover {
    color: ${COLORS.WHITE};
  }
`;

const WhatsappLink = () => (
  <p>
    Scrivici nella{" "}
    <StyledLink
      to="https://chat.whatsapp.com/HIBmmiUDsSYAf6Or6ux2Ul"
      target="_blank"
    >
      community WhatsApp <FAIcon icon={faWhatsapp} />
    </StyledLink>
    !
  </p>
);

export default WhatsappLink;
