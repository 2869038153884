import styled, { RuleSet } from "styled-components";
import { COLORS } from "../styles/colors";
import { Link } from "react-router-dom";
import { CSSProperties } from "react";

type Props = {
  media: "video" | "photo";
  position: CSSProperties["position"];
  bottom?: number;
  right?: number;
  plural?: boolean;
  title: string;
  url: string;
  background?: RuleSet<object>;
};

type StyleProps = {
  $position: CSSProperties["position"];
  $bottom: number;
  $right: number;
  $background?: RuleSet<object>;
};

const Label = styled.div<StyleProps>`
  position: ${({ $position }) => $position};
  bottom: ${({ $bottom }) => $bottom}px;
  margin-bottom: ${({ $position, $bottom }) =>
    $position === "relative" ? `-${$bottom}px` : "0"};
  right: ${({ $right }) => $right}px;
  margin-right: ${({ $position, $right }) =>
    $position === "relative" ? `-${$right}px` : "0"};
  padding: 5px;
  color: ${COLORS.WHITE};
  font-size: min(3vw, 12px);
  text-align: right;
  ${({ $background }) => $background ?? ""}

  & a {
    color: ${COLORS.WHITE};
    font-weight: bold;
  }
`;

const CreditsLabel = ({
  media,
  plural,
  title,
  url,
  background,
  position,
  bottom = 0,
  right = 0,
}: Props) => (
  <Label
    $position={position}
    $bottom={bottom}
    $right={right}
    $background={background}
  >
    {(media + (plural ? "s" : "")).toUpperCase()} COURTESY OF{" "}
    <Link to={url} target="_blank">
      {title.toUpperCase().replace(/ /g, "\u00a0")}
    </Link>
  </Label>
);
export default CreditsLabel;
