import { Fragment, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  HP_CARD_GAP,
  HP_CARD_HEIGHT,
  HP_CARD_MIN_WIDTH,
  MAIN_PADDING,
  HP_VIDEO_RATIO,
} from "../../../common/constants/numeric";
import {
  selectHeaderHeight,
  selectMaxHeaderHeight,
} from "../../../common/slices/headerSlice";
import HomePageCard from "./HomePageCard";
import Divider from "../../../common/components/Divider";
import { HomePageCardType } from "../../../common/types/HomePageCardType";
import { GlobalState } from "../../../common/types/GlobalState";
import {
  selectHomePageCards,
  selectHomePageLoading,
} from "../slices/homePageSlice";
import { debounce } from "lodash";
import Loader from "../../../common/components/Loader";
import VideoPlayer from "./VideoPlayer";

const CARDS_ROW_SIZE_MAX = 4;
const MAX_ORIENTATION_MODIFIER = 3;

type Props = {
  cards: HomePageCardType[];
  headerHeight: number;
  maxHeaderHeight: number;
  homePageLoading: boolean;
};

const CardContainerWithVideo = ({
  cards,
  headerHeight,
  maxHeaderHeight,
  homePageLoading,
}: Props) => {
  const [cachedWindowData, setCachedWindowData] = useState({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
    orientation: window.matchMedia("(orientation: portrait)").matches
      ? "portrait"
      : "landscape",
  });
  // eslint-disable-next-line
  const [_cachedScrollY, setCachedScrollY] = useState(window.scrollY);

  const calcColumnsPerRow = useCallback(
    () =>
      Math.min(
        CARDS_ROW_SIZE_MAX,
        Math.floor(
          (cachedWindowData.width - 2 * MAIN_PADDING + HP_CARD_GAP) /
            (HP_CARD_MIN_WIDTH + HP_CARD_GAP)
        )
      ),
    [cachedWindowData.width]
  );

  const calcColumnWidths = useCallback(
    (): number[] =>
      cards.map((card) => Math.min(card.columns ?? 1, calcColumnsPerRow())),
    [cards, calcColumnsPerRow]
  );

  const calcTotalColumns = useCallback(
    () =>
      calcColumnWidths().reduce((colCount, width) => (colCount += width), 0),
    [calcColumnWidths]
  );

  const handleScroll = () => {
    // cachedScrollY is updated but not read; this appears to fix the scrolling behavior without using the cached value instead of actual window.scrollY
    // Might need to revisit later, especially if performance degrades
    // TODO: Find accurate explanation
    setCachedScrollY(window.scrollY);
  };

  const handleResize = debounce(() => {
    setCachedWindowData(() => {
      return {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
        orientation: window.matchMedia("(orientation: portrait)").matches
          ? "portrait"
          : "landscape",
      };
    });
  }, 50);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    window.addEventListener("resize", handleResize, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const calcCardWidth = useCallback(() => {
    if (!cachedWindowData.width) return 0;
    const cardsPerRow = calcColumnsPerRow();
    return (
      (cachedWindowData.width -
        2 * MAIN_PADDING -
        (cardsPerRow - 1) * HP_CARD_GAP) /
      cardsPerRow
    );
  }, [cachedWindowData.width, calcColumnsPerRow]);

  const calcMultiColumnCardWidth = useCallback(
    (columns: number) => {
      const rowSize = calcColumnsPerRow();
      const columnsNumber = Math.min(columns, rowSize);
      return (
        calcCardWidth() * columnsNumber +
        HP_CARD_GAP * Math.max(0, columnsNumber - 1)
      );
    },
    [calcCardWidth, calcColumnsPerRow]
  );

  const getOrientationModifier = useCallback(() => {
    return cachedWindowData.orientation === "portrait"
      ? 1
      : Math.min(
          MAX_ORIENTATION_MODIFIER,
          Math.max(
            1,
            cachedWindowData.width /
              HP_VIDEO_RATIO /
              Math.max(
                1,
                cachedWindowData.height -
                  maxHeaderHeight -
                  HP_CARD_HEIGHT -
                  2 * MAIN_PADDING
              )
          )
        );
  }, [cachedWindowData, maxHeaderHeight]);

  const calcVideoHeight = useCallback(
    () => cachedWindowData.width / HP_VIDEO_RATIO / getOrientationModifier(),
    [cachedWindowData.width, getOrientationModifier]
  );

  const calcRowsAboveVideo = useCallback(() => {
    const rowsVisible =
      (cachedWindowData.height -
        maxHeaderHeight -
        calcVideoHeight() -
        MAIN_PADDING) /
      (HP_CARD_HEIGHT + HP_CARD_GAP);
    return Math.max(0, Math.floor(rowsVisible));
  }, [cachedWindowData.height, calcVideoHeight, maxHeaderHeight]);

  const calcVideoWrapperHeight = useCallback(
    () =>
      cachedWindowData.width
        ? Math.max(0, Math.floor(calcVideoHeight() - window.scrollY))
        : 0,
    [cachedWindowData.width, calcVideoHeight]
  );

  const calcCardsAboveVideo = useCallback(() => {
    const rowsAboveVideo = calcRowsAboveVideo();
    if (rowsAboveVideo === 0) return 0;

    const columnsPerRow = calcColumnsPerRow();
    const columnWidths = calcColumnWidths();

    let currentRowAvailableCols = columnsPerRow;
    let currentRow = 1;
    let n = 0;
    for (const width of columnWidths) {
      if (width > currentRowAvailableCols) {
        currentRow++;
        currentRowAvailableCols = columnsPerRow;
      }

      if (currentRow > rowsAboveVideo) return n;

      currentRowAvailableCols -= width;
      n++;
    }

    return columnWidths.length;
  }, [calcColumnWidths, calcColumnsPerRow, calcRowsAboveVideo]);

  const columnsPerRow = calcColumnsPerRow();
  const rowsAboveVideo = calcRowsAboveVideo();
  const videoHeight = cachedWindowData.width / HP_VIDEO_RATIO;
  const videoWrapperHeight = calcVideoWrapperHeight();
  const totalColumns = calcTotalColumns();
  const cardsAboveVideo = calcCardsAboveVideo();
  const orientationModifier = getOrientationModifier();

  return (
    <>
      <div
        id="video-wrapper"
        style={{
          position: "absolute",
          top: `${
            headerHeight +
            MAIN_PADDING +
            (HP_CARD_HEIGHT + HP_CARD_GAP) *
              Math.min(rowsAboveVideo, Math.ceil(totalColumns / columnsPerRow))
          }px`,
          height: `${videoWrapperHeight}px`,
          width: "100vw",
          padding: "0",
          left: "0",
          overflow: "hidden",
        }}
      >
        {homePageLoading ? (
          <Loader
            style={{
              width: "100vw",
              height: videoWrapperHeight,
            }}
          />
        ) : (
          <VideoPlayer
            videoHeight={videoHeight}
            videoWrapperHeight={videoWrapperHeight}
            orientationModifier={orientationModifier}
            position="absolute"
          />
        )}
      </div>
      <div
        id="content"
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: `${HP_CARD_GAP}px`,
        }}
      >
        {cards.map((card, i) => (
          <Fragment key={`fragment-${i}`}>
            {i === cardsAboveVideo && (
              <Divider
                height={videoWrapperHeight}
                visible={videoWrapperHeight > 0}
              />
            )}
            <HomePageCard
              cardData={card}
              style={{
                width: `${calcMultiColumnCardWidth(card.columns ?? 1)}px`,
                height: `${HP_CARD_HEIGHT}px`,
              }}
            />
          </Fragment>
        ))}
        {cards.length <= cardsAboveVideo && (
          <Divider
            height={videoWrapperHeight}
            visible={videoWrapperHeight > 0}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: GlobalState) => {
  return {
    cards: selectHomePageCards(state),
    headerHeight: selectHeaderHeight(state),
    maxHeaderHeight: selectMaxHeaderHeight(state),
    homePageLoading: selectHomePageLoading(state),
  };
};

export default connect(mapStateToProps)(CardContainerWithVideo);
