import { useParams } from "react-router-dom";
import { GlobalState } from "../../common/types/GlobalState";
import {
  clearDynamicPageState,
  getDynamicPageRequest,
  selectDynamicPageLoadError,
  selectDynamicPageLoading,
  selectPage,
} from "./slices/dynamicPageSlice";
import { DynamicPageType } from "../../common/types/DynamicPageType";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { APP_TITLE } from "../../common/constants/strings";
import styled from "styled-components";
import Loader from "../../common/components/Loader";
import { COLORS } from "../../common/styles/colors";
import DynamicPageEditor from "../edit/dynamicpage/components/DynamicPageEditor";
import { selectCanEdit } from "../../common/slices/appSlice";
import DynamicPageDisplay from "./components/DynamicPageDisplay";
import Breadcrumbs from "../../common/components/Breadcrumbs";

type Props = {
  page?: DynamicPageType;
  loading: boolean;
  error: boolean;
  canEdit: boolean;
};

const ErrorContainer = styled.div`
  color: ${COLORS.MID_RED};
  text-align: center;
`;

const DynamicPage = ({ page, loading, error, canEdit }: Props) => {
  document.title = APP_TITLE + (page?.title ? " | " + page.title : "");

  const { dynamicPageId } = useParams();
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (page?.internalId && dynamicPageId !== page.internalId)
      dispatch(clearDynamicPageState());
    dynamicPageId && dispatch(getDynamicPageRequest(dynamicPageId));
  }, [dispatch, dynamicPageId, page?.internalId]);

  return loading ? (
    <Loader />
  ) : error ? (
    <ErrorContainer>
      <h4>Errore nel caricamento della pagina!</h4>
    </ErrorContainer>
  ) : editMode && page ? (
    <>
      <Breadcrumbs currentStep={page.title} />
      <DynamicPageEditor page={page} closeEditMode={() => setEditMode(false)} />
    </>
  ) : (
    <>
      <Breadcrumbs currentStep={page?.title} />
      <DynamicPageDisplay
        page={page}
        canEdit={canEdit}
        setEditMode={setEditMode}
      />
    </>
  );
};

const mapStateToProps = (state: GlobalState) => {
  return {
    page: selectPage(state),
    loading: selectDynamicPageLoading(state),
    error: selectDynamicPageLoadError(state),
    canEdit: selectCanEdit(state),
  };
};

export default connect(mapStateToProps)(DynamicPage);
