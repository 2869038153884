import { MutableRefObject, useContext, useRef } from "react";
import { InterestFormContext } from "../InterestForm";
import { InterestFormState } from "../../interestFormTypes";
import {
  InterestFormRadio,
  OptionsContainerColumn,
  OptionsContainerRow,
} from "../../interestFormComponents";
import React from "react";
import BackAndNext from "../BackAndNext";
import StepTrainingOtherLocations from "./StepTrainingOtherLocations";

const StepTrainingFrequency = () => {
  const { answers, answerStep } = useContext(InterestFormContext);

  const radioGroupRef: MutableRefObject<HTMLDivElement> = useRef(
    null as unknown as HTMLDivElement
  );

  const onNext = () => {
    const frequency = (
      radioGroupRef.current?.querySelector(
        'input[type="radio"]:checked'
      ) as HTMLInputElement
    )?.value;
    answerStep({ frequency } as InterestFormState, StepTrainingOtherLocations);
  };

  return (
    <div>
      <center>...e con che frequenza?</center>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <OptionsContainerRow>
          <OptionsContainerColumn style={{ border: 0 }}>
            <span>1 a settimana o più</span>
            <span>1 ogni 2 settimane</span>
            <span>1 ogni 3 settimane</span>
            <span>1 al mese o meno</span>
          </OptionsContainerColumn>
          <OptionsContainerColumn ref={radioGroupRef}>
            {["WEEKLY", "EVERY_2_WEEKS", "EVERY_3_WEEKS", "MONTHLY"].map(
              (frequency) => {
                return (
                  <InterestFormRadio
                    bigger
                    name="frequency"
                    key={frequency}
                    value={frequency}
                    defaultChecked={answers?.frequency === frequency}
                  />
                );
              }
            )}
          </OptionsContainerColumn>
        </OptionsContainerRow>
      </div>
      <BackAndNext onNext={onNext} />
    </div>
  );
};

export default StepTrainingFrequency;
