import { MutableRefObject, useContext, useRef, useState } from "react";
import { InterestFormContext } from "../InterestForm";
import {
  ErrorDTO,
  InterestFormState,
  ValidationError,
} from "../../interestFormTypes";
import Input from "../../../../common/components/inputs/Input";
import { isValidPhoneNumber } from "libphonenumber-js";
import { isNonBlankString } from "../../../../common/utils/stringUtils";
import Errors from "../Errors";
import BackAndNext from "../BackAndNext";
import StepTrainingTimePlace from "./StepTrainingTimePlace";
import StepInfoRequest from "./StepInfoRequest";
import FAIcon from "../../../../common/components/FAIcon";
import {
  faClipboardQuestion,
  faUserSecret,
} from "@fortawesome/free-solid-svg-icons";
import { AccentLink } from "../../../../common/components/links/styledLinks";
import { BigButton, JustifyOrCenter } from "../../interestFormComponents";
import StepEndPositive from "./StepEndPositive";
import { ReactComponent as SafeIcon } from "../../../../common/images/svg/safe-icon.svg";
import styled from "styled-components";
import { COLORS } from "../../../../common/styles/colors";

const GDPRContainer = styled.div`
  display: flex;
  height: 2.5em;
  width: fit-content;
  justify-content: center;
  border: outset 2px ${COLORS.MID_GREEN};
  border-radius: 10px;
  margin: 0 auto;
  padding: 10px;
`;

const StepContacts = () => {
  const { answers, answerStep, handleNo } = useContext(InterestFormContext);
  const [errors, setErrors] = useState([] as ErrorDTO[]);
  const [showForm, setShowForm] = useState(false);
  const [validationFailures, setValidationFailures] = useState(
    {} as ValidationError
  );

  const nameRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const emailRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const fbRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const whatsappRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const telegramRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );

  const onNext = () => {
    setErrors([]);
    try {
      validate();
    } catch (e: any) {
      return;
    }
    answerStep(
      {
        contacts: {
          name: nameRef.current?.value,
          email: emailRef.current?.value,
          fb: fbRef.current?.value,
          whatsapp: whatsappRef.current?.value,
          telegram: telegramRef.current?.value,
        },
      } as InterestFormState,
      answers.needMoreInfo ? StepInfoRequest : StepTrainingTimePlace
    );
  };

  const validate = () => {
    const errs = [];

    const name = nameRef.current?.value;
    const email = emailRef.current?.value;
    const fb = fbRef.current?.value;
    const whatsapp = whatsappRef.current?.value;
    const telegram = telegramRef.current?.value;

    if (!isNonBlankString(name))
      errs.push({
        failures: ["name"],
        msg: "Come ti possiamo chiamare?",
      });

    if (
      !isNonBlankString(email) &&
      !isNonBlankString(fb) &&
      !isNonBlankString(whatsapp) &&
      !isNonBlankString(telegram)
    )
      errs.push({
        failures: ["email", "fb", "whatsapp", "telegram"],
        msg: "Inserisci almeno una modalità di contatto",
      });

    if (isNonBlankString(email) && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
      errs.push({
        failures: ["email"],
        msg: "Inserisci un indirizzo valido come contatto email",
      });

    if (isNonBlankString(whatsapp) && !isValidPhoneNumber(whatsapp, "IT"))
      errs.push({
        failures: ["whatsapp"],
        msg: "Inserisci un numero di telefono valido come contatto WhatsApp",
      });

    if (errs.length > 0) {
      setErrors(errs);
      throw new Error("Validation errors present");
    }
  };

  return showForm ? (
    <>
      <div>
        <div>
          <>
            <Input
              ref={nameRef}
              id="name"
              type="text"
              label="Nome *"
              value={answers.contacts?.name}
              validationFailed={validationFailures.name}
            />
            <Input
              ref={emailRef}
              id="email"
              type="text"
              label="Email"
              value={answers.contacts?.email}
              validationFailed={validationFailures.email}
            />
            <Input
              ref={fbRef}
              id="fb"
              type="text"
              label="Facebook"
              value={answers.contacts?.fb}
              validationFailed={validationFailures.fb}
            />
            <Input
              ref={whatsappRef}
              id="whatsapp"
              type="text"
              label="WhatsApp"
              value={answers.contacts?.whatsapp}
              validationFailed={validationFailures.whatsapp}
            />
            <Input
              ref={telegramRef}
              id="telegram"
              type="text"
              label="Telegram"
              value={answers.contacts?.telegram}
              validationFailed={validationFailures.telegram}
            />
          </>
        </div>
      </div>
      <BackAndNext onNext={onNext} />
      <Errors errors={errors} setValidationFailures={setValidationFailures} />
    </>
  ) : (
    <>
      <JustifyOrCenter>
        <h2>Dati di contatto</h2>
        <h3>
          <FAIcon icon={faClipboardQuestion} /> Perché mi chiedete questi dati?
        </h3>
        <p style={{ textAlign: "justify" }}>
          Avere uno o più modi per contattarti è molto utile per noi! In questo
          modo abbiamo una visione più chiara degli interessati, possiamo
          tenerti direttamente aggiornato su allenamenti ed eventi, e rispondere
          facilmente ai tuoi dubbi.
        </p>
        <h3>
          <FAIcon icon={faUserSecret} /> I miei dati sono al sicuro?
        </h3>
        <GDPRContainer>
          <div>
            <SafeIcon style={{ height: "100%", marginRight: "10px" }} />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <code>GDPR Compliant</code>
          </div>
        </GDPRContainer>
        <p style={{ textAlign: "justify" }}>
          In cambio della tua fiducia, ti promettiamo che i tuoi dati saranno
          trattati con riservatezza e non verranno condivisi con terzi. Puoi
          leggere la{" "}
          <AccentLink href="/privacy/interest" target="_blank">
            Privacy Policy per questo Modulo di manifestazione di interesse
          </AccentLink>{" "}
          per avere più dettagli.
          <br />
          Se ci chiederai di cancellare i tuoi dati in modo parziale o totale,
          lo faremo immediatamente e smetteremo di contattarti sui canali che ci
          hai richiesto di non usare.
        </p>
        <p style={{ textAlign: "justify" }}>
          Se preferisci non fornire i tuoi dati, ti chiediamo se vuoi di
          iscriverti alla nostra community WhatsApp. Questo è un metodo più
          diretto dei nostri canali social, e puoi uscire in qualsiasi momento.
        </p>
      </JustifyOrCenter>
      <BigButton onClick={() => setShowForm(true)}>Ok, rispondo!</BigButton>
      <BigButton
        onClick={() =>
          answerStep(
            { contacts: { community: true } } as InterestFormState,
            StepEndPositive
          )
        }
      >
        Preferisco iscrivermi alla community WhatsApp!
      </BigButton>
      <BigButton onClick={handleNo}>No grazie</BigButton>
    </>
  );
};

export default StepContacts;
