import { DynamicPageType } from "../../../../common/types/DynamicPageType";
import "@mdxeditor/editor/style.css";
import MarkdownEditor from "../../../../common/components/inputs/MarkdownEditor";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Input from "../../../../common/components/inputs/Input";
import { connect, useDispatch } from "react-redux";
import {
  saveDynamicPageRequest,
  selectDynamicPageSaveError,
  selectDynamicPageSaving,
} from "../../../dynamicpage/slices/dynamicPageSlice";
import ReactModal from "react-modal";
import { DynamicPageDTO } from "../../../../common/types/dtos/DynamicPageDTO";
import { GlobalState } from "../../../../common/types/GlobalState";
import { useIsValueChangedCallback } from "../../../../common/utils/refUtils";
import { isNonBlankString } from "../../../../common/utils/stringUtils";
import EditorButtonsMultiModal from "../../../../common/components/buttons/EditorButtonsMultiModal";
import MultiModalContext from "../../../../common/contexts/MultiModalContext";
import { selectImageUploading } from "../../../../common/slices/imgSlice";
import LoaderOverlayWrapper from "../../../../common/components/LoaderOverlayWrapper";

type Props = {
  page: DynamicPageType;
  closeEditMode: () => void;
  saving: boolean;
  saveError: boolean;
  uploadingImg: boolean;
};

ReactModal.setAppElement("#root");

const DynamicPageEditor = ({
  page,
  closeEditMode,
  saving,
  saveError,
  uploadingImg,
}: Props) => {
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const [_hasChanges, setHasChanges] = useState(false);

  const internalIdRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const titleRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const markdownRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const customStyleRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const activeRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const isInternalIdChanged = useIsValueChangedCallback(
    internalIdRef,
    page.internalId
  );
  const isTitleChanged = useIsValueChangedCallback(titleRef, page.title);
  const isMarkdownChanged = useIsValueChangedCallback(
    markdownRef,
    page.markdown
  );
  const isCustomStyleChanged = useIsValueChangedCallback(
    customStyleRef,
    page.customStyle
  );
  const isActiveChanged = useCallback(
    () => activeRef.current && activeRef.current?.checked !== page.active,
    [page.active]
  );
  const areThereChanges = useCallback(() => {
    if (!page?.internalId) {
      return (
        [internalIdRef, titleRef, markdownRef, customStyleRef].filter((ref) =>
          isNonBlankString(ref?.current?.value)
        ).length > 0
      );
    }

    return (
      isInternalIdChanged() ||
      isTitleChanged() ||
      isMarkdownChanged() ||
      isCustomStyleChanged() ||
      isActiveChanged()
    );
  }, [
    page?.internalId,
    isInternalIdChanged,
    isTitleChanged,
    isMarkdownChanged,
    isCustomStyleChanged,
    isActiveChanged,
  ]);

  const save = () => {
    const updatedPage = {
      internal_id: isInternalIdChanged()
        ? internalIdRef.current?.value
        : undefined,
      title: isTitleChanged() ? titleRef.current?.value : undefined,
      markdown: isMarkdownChanged() ? markdownRef.current?.value : undefined,
      custom_style: isCustomStyleChanged()
        ? customStyleRef.current?.value
        : undefined,
      active: isActiveChanged() ? activeRef.current?.checked : undefined,
    } as DynamicPageDTO;
    const payload = {
      id: page.internalId,
      page: updatedPage,
    };

    dispatch(saveDynamicPageRequest(payload));
  };

  const updateChanges = () => {
    setHasChanges(areThereChanges());
  };
  useEffect(updateChanges, [areThereChanges]);

  const editorButtons = (
    <EditorButtonsMultiModal
      saving={saving}
      saveError={saveError}
      save={save}
      areThereChanges={areThereChanges}
      closeEditMode={closeEditMode}
    />
  );

  const [isModalOpen, setModalOpen] = useState(false);
  const [customStyleCurrent, setCustomStyleCurrent] = useState(
    page.customStyle
  );

  return (
    <MultiModalContext.Provider value={{ isModalOpen, setModalOpen }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          position: "relative",
        }}
      >
        {editorButtons}
        <Input
          ref={internalIdRef}
          id="internal-id"
          value={page.internalId}
          label="ID Pagina"
          type="text"
          onUpdate={updateChanges}
        />
        <Input
          ref={titleRef}
          id="title"
          value={page.title}
          label="Titolo"
          type="text"
          onUpdate={updateChanges}
        />
        <LoaderOverlayWrapper showLoader={uploadingImg}>
          <MarkdownEditor
            ref={markdownRef}
            markdown={page.markdown}
            onUpdate={updateChanges}
            path="dynamicpages/markdown"
            customStyle={customStyleCurrent}
          />
        </LoaderOverlayWrapper>
        <Input
          ref={customStyleRef}
          id="custom-style"
          value={page.customStyle}
          label="Stili aggiuntivi"
          type="css"
          onUpdate={() => {
            updateChanges();
            setCustomStyleCurrent(customStyleRef?.current?.value);
          }}
        />
        <Input
          ref={activeRef}
          id="active"
          value={page.active}
          label="Attiva?"
          type="checkbox"
          onUpdate={updateChanges}
        />
        {editorButtons}
      </div>
    </MultiModalContext.Provider>
  );
};

const mapStateToProps = (state: GlobalState) => {
  return {
    saving: selectDynamicPageSaving(state),
    saveError: selectDynamicPageSaveError(state),
    uploadingImg: selectImageUploading(state),
  };
};

export default connect(mapStateToProps)(DynamicPageEditor);
