import React from "react";
import { COLORS } from "../styles/colors";
import styled from "styled-components";
import FAIcon from "./FAIcon";
import {
  faCamera,
  faCookieBite,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

const FooterLink = styled.a`
  color: inherit;
  font-weight: bold;
  text-decoration: none !important;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
`;

const Footer: React.FC = () => {
  return (
    <footer
      style={{
        backgroundColor: COLORS.DARK_ORANGE,
        color: "white",
        padding: "20px",
        position: "relative",
        bottom: 0,
        width: "100%",
        boxSizing: "border-box",
        fontSize: "0.8em",
        marginTop: 50,
        borderTop: `5px solid ${COLORS.MID_YELLOW}`,
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          padding: "0 20px",
        }}
      >
        <div
          style={{
            flex: "1 1 auto",
            textAlign: "left",
            minWidth: "fit-content",
          }}
        >
          <h2>Contatti</h2>
          <p>
            <FAIcon icon={faEnvelope} />{" "}
            <FooterLink href="mailto:play@quadball.fvg.it">
              play@quadball.fvg.it
            </FooterLink>
          </p>
          <p>
            <FAIcon icon={faWhatsapp} />{" "}
            <FooterLink
              href="https://chat.whatsapp.com/HIBmmiUDsSYAf6Or6ux2Ul"
              target="_blank"
            >
              WhatsApp
            </FooterLink>
          </p>
          <p>
            <FAIcon icon={faFacebook} />{" "}
            <FooterLink
              href="https://www.facebook.com/quadballfvg"
              target="_blank"
            >
              Facebook
            </FooterLink>
          </p>
          <p>
            <FAIcon icon={faInstagram} />{" "}
            <FooterLink
              href="https://www.instagram.com/quadballfvg"
              target="_blank"
            >
              Instagram
            </FooterLink>
          </p>
        </div>
        <div
          style={{
            flex: "1 1 auto",
            textAlign: "left",
            minWidth: "fit-content",
          }}
        >
          <h2>Informazioni utili</h2>
          <p>
            <FAIcon icon={faCamera} /> Photos courtesy of{" "}
            <FooterLink
              href="https://www.facebook.com/VanKlaverenQP"
              target="_blank"
            >
              Van Klaveren Quidditch Photography
            </FooterLink>
          </p>
          <p>
            <FooterLink href="/cookies" target="_blank">
              <FAIcon icon={faCookieBite} /> Cookie Policy
            </FooterLink>
          </p>
          <p>Website by Michele Clabassi</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
