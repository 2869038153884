import React, { useState } from "react";
import LoaderOverlay from "../../../common/components/LoaderOverlay";
import { CSSProperties } from "react";
import Player from "@vimeo/player";
import CreditsLabel from "../../../common/components/CreditsLabel";
import { css } from "styled-components";

interface VideoPlayerProps {
  videoHeight: CSSProperties["height"];
  videoWrapperHeight?: number;
  orientationModifier?: number;
  position?: CSSProperties["position"];
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  videoHeight,
  videoWrapperHeight,
  orientationModifier,
  position,
}: VideoPlayerProps) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  return (
    <>
      {!isVideoPlaying && <LoaderOverlay />}
      <iframe
        onLoad={(e) => {
          const player = new Player(e.target as HTMLIFrameElement);
          player.on("play", function () {
            setIsVideoPlaying(true);
          });
        }}
        title="Background Video"
        style={{
          border: 0,
          height: videoHeight,
          width: "100vw",
          position: position,
          bottom:
            videoWrapperHeight && orientationModifier
              ? `${
                  -window.scrollY / 2 -
                  (videoWrapperHeight -
                    videoWrapperHeight / orientationModifier)
                }px`
              : undefined,
        }}
        src="https://player.vimeo.com/video/1062977237?badge=0&amp;autoplay=1&amp;loop=1&amp;background=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
      ></iframe>
      <CreditsLabel
        media="video"
        position="absolute"
        title="Major League Quadball"
        url="https://mlquadball.com/"
        background={css`
          background: rgb(33, 33, 33, 0.5);
        `}
      />
    </>
  );
};

export default VideoPlayer;
