import { useContext } from "react";
import { InterestFormContext } from "../InterestForm";
import WhatsappLink from "../WhatsappLink";
import useFinalSave from "../../hooks/useFinalSave";

const StepEndPositive = () => {
  useFinalSave();
  const { answers } = useContext(InterestFormContext);

  return (
    <div>
      {answers?.contacts?.community || (
        <center>
          Grazie,{" "}
          {answers.needMoreInfo
            ? "ti contatteremo per rispondere alle tue domande!"
            : "ti contatteremo per il prossimo allenamento o evento!"}
        </center>
      )}
      <div style={{ textAlign: "center", margin: "20px 0" }}>
        <WhatsappLink />
        {answers?.contacts?.community && "Ci vediamo lì."}
      </div>
    </div>
  );
};

export default StepEndPositive;
