import styled from "styled-components";
import { COLORS } from "../../styles/colors";
import { Link } from "react-router-dom";

const linkStyles = `
  color: ${COLORS.MID_ORANGE};
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: underline;
  }
`;

export const AccentLink = styled.a`
  ${linkStyles}
`;

export const AccentDomLink = styled(Link)`
  ${linkStyles}
`;
