import { MutableRefObject, useContext, useRef, useState } from "react";
import Input from "../../../../common/components/inputs/Input";
import { BigButton, InterestFormImage } from "../../interestFormComponents";
import { InterestFormContext } from "../InterestForm";
import {
  ErrorDTO,
  InterestFormState,
  ValidationError,
} from "../../interestFormTypes";
import { isNonBlankString } from "../../../../common/utils/stringUtils";
import Errors from "../Errors";
import StepNoWithComments from "./StepNoWithComments";

const StepNo = () => {
  const { answers, answerStep } = useContext(InterestFormContext);
  const [errors, setErrors] = useState([] as ErrorDTO[]);
  const [validationFailures, setValidationFailures] = useState(
    {} as ValidationError
  );
  const commentsRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );

  const onClick = () => {
    setErrors([]);
    try {
      validate();
    } catch (e: any) {
      return;
    }
    answerStep(
      {
        comments: "Risposta: [NO] | " + commentsRef.current?.value,
      } as InterestFormState,
      StepNoWithComments
    );
  };

  const validate = () => {
    const errs = [];

    const comments = commentsRef.current?.value;

    if (!isNonBlankString(comments))
      errs.push({
        failures: ["comments"],
        msg: "Se non vuoi lasciare un commento, chiudi pure la pagina!",
      });

    if (errs.length > 0) {
      setErrors(errs);
      throw new Error("Validation errors present");
    }
  };

  return (
    <>
      <center>Non lasciarci! 😢</center>
      <InterestFormImage
        src="https://res.cloudinary.com/dfml8txza/image/upload/f_auto,q_auto/v1/quadballfvg/interest/l6pzfuhis9wjyfjbzmey"
        style={{ width: "100%" }}
      />
      <center>
        Ci dispiace vederti andare via, ma se dovessi cambiare idea ci trovi
        sempre qua!
        <br />
        Ti va di raccontarci perché hai risposto di no?
      </center>
      <Input
        ref={commentsRef}
        id="comments"
        type="textarea"
        value={answers.comments}
        validationFailed={validationFailures.comments}
      />
      <Errors errors={errors} setValidationFailures={setValidationFailures} />
      <BigButton onClick={onClick}>Fine</BigButton>
    </>
  );
};

export default StepNo;
