import { COLORS } from "../styles/colors";

export enum Variant {
  Primary,
  Secondary,
  Neutral,
  Success,
  Failure,
}

export const variantToColors: any = {
  [Variant.Primary]: {
    main: COLORS.DARK_ORANGE,
    active: COLORS.MID_ORANGE,
    text: COLORS.WHITE,
  },
  [Variant.Secondary]: {
    main: COLORS.DARK_YELLOW,
    active: COLORS.MID_YELLOW,
    text: COLORS.WHITE,
  },
  [Variant.Neutral]: {
    main: COLORS.MID_GRAY,
    active: COLORS.LIGHT_GRAY,
    text: COLORS.WHITE,
  },
  [Variant.Success]: {
    main: COLORS.MID_GREEN,
    active: COLORS.DARK_GREEN,
    text: COLORS.WHITE,
  },
  [Variant.Failure]: {
    main: COLORS.MID_RED,
    active: COLORS.MID_RED,
    text: COLORS.WHITE,
  },
};
