function makeRoutes<T>(routes: T): T {
  return routes;
}

export const routes = makeRoutes({
  HOME: {
    path: "/",
    display: "🏠 Home",
    DYNAMIC_PAGE: {
      path: "/p/:dynamicPageId",
      hideBreadcrumbs: true,
    },
    COOKIES: {
      path: "/cookies",
      display: "🍪 Cookie Policy",
      hideHeader: true,
      hideFooter: true,
    },
    EDIT: {
      path: "/edit",
      display: "📝 Editor",
      hideFooter: true,
      DYNAMIC_PAGE_LIST: {
        path: "/edit/page",
        display: "Gestione pagine dinamiche",
        hideFooter: true,
        DYNAMIC_PAGE_NEW: {
          path: "/edit/page/new",
          display: "Nuova pagina dinamica",
          hideFooter: true,
        },
        DYNAMIC_PAGE_EDIT: {
          path: "/edit/page/:dynamicPageId",
          display: "Modifica pagina dinamica",
          hideFooter: true,
        },
      },
      CARD_LIST: {
        path: "/edit/card",
        display: "Gestione Card Homepage",
        hideFooter: true,
        CARD_NEW: {
          path: "/edit/card/new",
          display: "Nuova Card Homepage",
          hideFooter: true,
        },
        CARD_EDIT: {
          path: "/edit/card/:cardId",
          display: "Modifica Card Homepage",
          hideFooter: true,
        },
      },
    },
  },
  INTEREST_FORM: {
    path: "/interest",
    display: "📋 Modulo manifestazione d'interesse",
    hideBreadcrumbs: true,
    hideFooter: true,
    INTEREST_FORM_PRIVACY: {
      path: "/privacy/interest",
      display: "Informativa sulla privacy",
      hideFooter: true,
    },
  },
  LOGIN: {
    path: "/login",
    display: "Login",
    hideFooter: true,
  },
  DYNAMIC_PAGE_PREVIEW: {
    path: "/edit/page/:dynamicPageId/preview",
  },
  WORK_IN_PROGRESS: {
    path: "/wip",
    display: "Work in Progress",
    hideFooter: true,
  },
} as const);

export const apiRoutes = {
  LOGIN: "/api/login",
  LOGOUT: "/api/logout",
  USER_CONTEXT: "/api/user/context",
  CARD: {
    GET_ACTIVE: "/api/cards",
    SAVE: "/api/card/save/:cardId",
    SORT: "/api/card/sort",
    EDIT: {
      LIST: "/api/card/e",
      GET: "/api/card/e/:cardId",
    },
  },
  DYNAMIC_PAGE: {
    GET: "/api/page/:dynamicPageId",
    SAVE: "/api/page/save/:dynamicPageId",
    EDIT: {
      LIST: "/api/page/e",
      GET: "/api/page/e/:dynamicPageId",
    },
  },
  IMAGE_UPLOAD: "/api/img/upload/:path",
  INTEREST_FORM: "/api/interest/:leadId",
} as const;
