import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DynamicPageType } from "../../../common/types/DynamicPageType";
import { GlobalState } from "../../../common/types/GlobalState";
import {
  DynamicPageDTO,
  convertDTO,
} from "../../../common/types/dtos/DynamicPageDTO";
import { updateHistory } from "../../../common/utils/historyUtils";

type State = {
  page?: DynamicPageType;
  loading: boolean;
  loadError: boolean;
  saving: boolean;
  saveError: boolean;
  pages?: DynamicPageType[];
};

const initialState = {
  loading: true,
  loadError: false,
  saving: false,
  saveError: false,
};

const dynamicPageSlice = createSlice({
  name: "dynamicPage",
  initialState,
  reducers: {
    clearDynamicPageState: () => initialState,
    getDynamicPageRequest: (state, action) => {},
    getDynamicPageSuccess: (
      state: State,
      { payload }: PayloadAction<DynamicPageDTO>
    ) => {
      state.page = convertDTO(payload);
      state.loading = false;
    },
    getDynamicPageFailure: (state) => {
      state.loadError = true;
      state.loading = false;
    },
    saveDynamicPageRequest: (state, action) => {
      state.saving = true;
      state.saveError = false;
    },
    saveDynamicPageSuccess: (
      state: State,
      { payload }: PayloadAction<DynamicPageDTO>
    ) => {
      const newPage = convertDTO(payload);
      if (
        !state.page?.internalId ||
        state.page.internalId !== newPage.internalId
      ) {
        updateHistory(newPage.internalId);
      }
      state.page = newPage;
      state.saving = false;
    },
    saveDynamicPageFailure: (state) => {
      state.saveError = true;
      state.saving = false;
    },
    listEDynamicPagesRequest: () => {},
    listEDynamicPagesSuccess: (
      state: State,
      { payload }: PayloadAction<DynamicPageDTO[]>
    ) => {
      state.pages = payload.map(convertDTO);
    },
    listEDynamicPagesFailure: () => {},
    getEDynamicPageRequest: (state, action) => {},
    getEDynamicPageSuccess: (
      state: State,
      { payload }: PayloadAction<DynamicPageDTO>
    ) => {
      state.page = convertDTO(payload);
    },
    getEDynamicPageFailure: () => {},
  },
});

export const {
  clearDynamicPageState,
  getDynamicPageRequest,
  getDynamicPageSuccess,
  getDynamicPageFailure,
  saveDynamicPageRequest,
  saveDynamicPageSuccess,
  saveDynamicPageFailure,
  listEDynamicPagesRequest,
  listEDynamicPagesSuccess,
  listEDynamicPagesFailure,
  getEDynamicPageRequest,
  getEDynamicPageSuccess,
  getEDynamicPageFailure,
} = dynamicPageSlice.actions;

export const selectPage = (state: GlobalState) => state.dynamicPage?.page;
export const selectDynamicPageLoading = (state: GlobalState) =>
  state.dynamicPage?.loading;
export const selectDynamicPageLoadError = (state: GlobalState) =>
  state.dynamicPage?.loadError;
export const selectDynamicPageSaving = (state: GlobalState) =>
  state.dynamicPage?.saving;
export const selectDynamicPageSaveError = (state: GlobalState) =>
  state.dynamicPage?.saveError;
export const selectEDynamicPages = (state: GlobalState) =>
  state.dynamicPage?.pages;
export const selectPageForEditor = (state: GlobalState) =>
  state.dynamicPage?.page;

export default dynamicPageSlice.reducer;
