import "@mdxeditor/editor/style.css";
import MarkdownEditor from "../../../../common/components/inputs/MarkdownEditor";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Input from "../../../../common/components/inputs/Input";
import { Variant, variantToColors } from "../../../../common/enums/Variant";
import { connect, useDispatch } from "react-redux";
import ReactModal from "react-modal";
import { GlobalState } from "../../../../common/types/GlobalState";
import { HomePageCardType } from "../../../../common/types/HomePageCardType";
import { HomePageCardDTO } from "../../../../common/types/dtos/HomePageCardDTO";
import {
  saveCardRequest,
  selectHomePageCardSaveError,
  selectHomePageCardSaving,
} from "../../../home/slices/homePageSlice";
import { useIsValueChangedCallback } from "../../../../common/utils/refUtils";
import { isNonBlankString } from "../../../../common/utils/stringUtils";
import {
  listEDynamicPagesRequest,
  selectEDynamicPages,
} from "../../../dynamicpage/slices/dynamicPageSlice";
import { DynamicPageType } from "../../../../common/types/DynamicPageType";
import EditorButtonsMultiModal from "../../../../common/components/buttons/EditorButtonsMultiModal";
import MultiModalContext from "../../../../common/contexts/MultiModalContext";
import LoaderOverlayWrapper from "../../../../common/components/LoaderOverlayWrapper";
import { selectImageUploading } from "../../../../common/slices/imgSlice";

type Props = {
  card: HomePageCardType;
  closeEditMode: () => void;
  saving: boolean;
  saveError: boolean;
  pages: DynamicPageType[];
  uploadingImg: boolean;
};

ReactModal.setAppElement("#root");

const CardEditor = ({
  card,
  closeEditMode,
  pages,
  saving,
  saveError,
  uploadingImg,
}: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listEDynamicPagesRequest());
  }, [dispatch]);

  // eslint-disable-next-line
  const [_hasChanges, setHasChanges] = useState(false);

  const internalIdRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const markdownRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const variantRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const bgImageUrlRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const columnsRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const customStyleRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const dynamicPageIdRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const externalLinkRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const activeRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const isInternalIdChanged = useIsValueChangedCallback(
    internalIdRef,
    card.internalId
  );
  const isMarkdownChanged = useIsValueChangedCallback(
    markdownRef,
    card.markdown
  );
  const isVariantChanged = useIsValueChangedCallback(
    variantRef,
    card.variant ? Variant[card.variant] : undefined
  );
  const isCustomStyleChanged = useIsValueChangedCallback(
    customStyleRef,
    card.customStyle
  );
  const isBgImageUrlChanged = useIsValueChangedCallback(
    bgImageUrlRef,
    card.bgImageUrl
  );
  const isColumnsChanged = useCallback(() => {
    if (!columnsRef.current) return false;
    if (isNonBlankString(columnsRef.current?.value))
      return parseInt(columnsRef.current?.value) !== card.columns;
    else return !!card.columns;
  }, [card.columns]);
  const isDynamicPageIdChanged = useIsValueChangedCallback(
    dynamicPageIdRef,
    card.dynamicPageId
  );
  const isExternalLinkChanged = useIsValueChangedCallback(
    externalLinkRef,
    card.externalLink
  );
  const isActiveChanged = useCallback(
    () => !!activeRef.current && activeRef.current?.checked !== card.active,
    [card.active]
  );
  const areThereChanges = useCallback(() => {
    if (!card?.internalId) {
      return (
        [
          internalIdRef,
          markdownRef,
          variantRef,
          bgImageUrlRef,
          columnsRef,
          customStyleRef,
          dynamicPageIdRef,
          externalLinkRef,
        ].filter((ref) => isNonBlankString(ref?.current?.value)).length > 0
      );
    }

    return (
      isInternalIdChanged() ||
      isMarkdownChanged() ||
      isVariantChanged() ||
      isBgImageUrlChanged() ||
      isColumnsChanged() ||
      isCustomStyleChanged() ||
      isDynamicPageIdChanged() ||
      isExternalLinkChanged() ||
      isActiveChanged()
    );
  }, [
    card?.internalId,
    isInternalIdChanged,
    isMarkdownChanged,
    isVariantChanged,
    isBgImageUrlChanged,
    isColumnsChanged,
    isCustomStyleChanged,
    isDynamicPageIdChanged,
    isExternalLinkChanged,
    isActiveChanged,
  ]);

  const save = () => {
    const updatedPage = {
      internal_id: isInternalIdChanged()
        ? internalIdRef.current?.value
        : undefined,
      markdown: isMarkdownChanged() ? markdownRef.current?.value : undefined,
      variant: isVariantChanged() ? variantRef.current?.value : undefined,
      bg_image_url: isBgImageUrlChanged()
        ? bgImageUrlRef.current?.value
        : undefined,
      columns: isColumnsChanged()
        ? parseInt(columnsRef.current?.value)
        : undefined,
      custom_style: isCustomStyleChanged()
        ? customStyleRef.current?.value
        : undefined,
      dynamic_page_id: isDynamicPageIdChanged()
        ? dynamicPageIdRef.current?.value
        : undefined,
      external_link: isExternalLinkChanged()
        ? externalLinkRef.current?.value
        : undefined,
      active: isActiveChanged() ? activeRef.current?.checked : undefined,
    } as HomePageCardDTO;
    const payload = {
      id: card.internalId,
      card: updatedPage,
    };

    dispatch(saveCardRequest(payload));
  };

  const updateChanges = () => {
    setHasChanges(areThereChanges());
  };
  useEffect(updateChanges, [areThereChanges]);

  const editorButtons = (
    <EditorButtonsMultiModal
      saving={saving}
      saveError={saveError}
      save={save}
      areThereChanges={areThereChanges}
      closeEditMode={closeEditMode}
    />
  );

  const [isModalOpen, setModalOpen] = useState(false);
  const [customStyleCurrent, setCustomStyleCurrent] = useState(
    card.customStyle
  );

  return (
    pages && (
      <MultiModalContext.Provider value={{ isModalOpen, setModalOpen }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            position: "relative",
          }}
        >
          {editorButtons}
          <Input
            ref={internalIdRef}
            id="internal-id"
            value={card.internalId}
            label="ID Card"
            type="text"
            onUpdate={updateChanges}
          />
          <LoaderOverlayWrapper showLoader={uploadingImg}>
            <MarkdownEditor
              ref={markdownRef}
              markdown={card.markdown ?? ""}
              onUpdate={updateChanges}
              path="homepagecards/markdown"
              customStyle={customStyleCurrent}
            />
          </LoaderOverlayWrapper>
          <Input
            ref={variantRef}
            id="variant"
            value={card.variant ? Variant[card.variant] : undefined}
            label="Variante"
            type="dropdown"
            options={Object.values(Variant)
              .filter((v) => isNaN(Number(v)))
              .map((v) => {
                return { value: v, label: v };
              })}
            customOptionStyle={(data: { value: string; label: string }) => {
              if (!data?.value) return {};
              const colorsMap =
                variantToColors[Variant[data.value as keyof typeof Variant]];
              return {
                background: colorsMap["main"],
                color: colorsMap["text"],
                ":hover": {
                  background: colorsMap["active"],
                },
              };
            }}
            onUpdate={updateChanges}
          />
          <LoaderOverlayWrapper showLoader={uploadingImg}>
            <Input
              ref={bgImageUrlRef}
              id="bg-image-url"
              value={card.bgImageUrl}
              label="Sfondo"
              type="image"
              onUpdate={updateChanges}
              path="homepagecards/background"
            />
          </LoaderOverlayWrapper>
          <Input
            ref={columnsRef}
            id="columns"
            value={card.columns ? card.columns.toString() : ""}
            label="Numero di colonne"
            type="number"
            onUpdate={updateChanges}
          />
          <Input
            ref={customStyleRef}
            id="custom-style"
            value={card.customStyle}
            label="Stili aggiuntivi"
            type="css"
            onUpdate={() => {
              updateChanges();
              setCustomStyleCurrent(customStyleRef?.current?.value);
            }}
          />
          {/* TODO: add static page link field */}
          {/* TODO: wrap links in group (alternative selection? advanced selection?) */}
          <Input
            ref={dynamicPageIdRef}
            id="dynamic-page-id"
            value={card.dynamicPageId}
            label="Link a pagina dinamica"
            type="dropdown"
            options={pages.map((p) => {
              return { value: p.internalId, label: p.internalId };
            })}
            onUpdate={updateChanges}
          />
          <Input
            ref={externalLinkRef}
            id="external-link"
            value={card.externalLink}
            label="Link esterno"
            type="text"
            onUpdate={updateChanges}
          />
          <Input
            ref={activeRef}
            id="active"
            value={card.active}
            label="Attiva?"
            type="checkbox"
            onUpdate={updateChanges}
          />
          {editorButtons}
        </div>
      </MultiModalContext.Provider>
    )
  );
};

const mapStateToProps = (state: GlobalState) => {
  return {
    saving: selectHomePageCardSaving(state),
    saveError: selectHomePageCardSaveError(state),
    pages: selectEDynamicPages(state),
    uploadingImg: selectImageUploading(state),
  };
};

export default connect(mapStateToProps)(CardEditor);
