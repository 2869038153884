import { MutableRefObject, useContext, useRef } from "react";
import { InterestFormContext } from "../InterestForm";
import { InterestFormState } from "../../interestFormTypes";
import Input from "../../../../common/components/inputs/Input";
import { BigButton } from "../../interestFormComponents";
import StepEndPositive from "./StepEndPositive";

const StepComments = () => {
  const { answers, answerStep, backButton } = useContext(InterestFormContext);

  const commentsRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );

  return (
    <div>
      <center>Vuoi comunicarci o chiederci qualcos'altro?</center>
      <Input
        ref={commentsRef}
        id="comments"
        type="textarea"
        value={answers.comments}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {backButton}
        <BigButton
          onClick={() =>
            answerStep(
              { comments: commentsRef.current?.value } as InterestFormState,
              StepEndPositive
            )
          }
          style={{ flex: 1 }}
        >
          Fine
        </BigButton>
      </div>
    </div>
  );
};

export default StepComments;
