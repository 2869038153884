import React, { useEffect, useState, useRef } from "react";

const SiteTitle = () => {
  const [topText, setTopText] = useState("\u00A0");
  const [bottomText, setBottomText] = useState("\u00A0");
  const [underline, setUnderline] = useState(false);

  const effectRan = useRef(false);
  const titleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (effectRan.current) return;
    effectRan.current = true;
    const quidditch = "QUIDDITCH";
    const fvg = "FVG";
    const timeoutTyping = 150;
    const timeoutSpellcheck = 1000;
    const timeoutDeleting = 100;
    const timeoutBeforeActing = 1500;
    const timeoutShortPause = 500;

    // Add QUIDDITCH one letter at a time
    quidditch.split("").forEach((char, index) => {
      setTimeout(
        () => setTopText((prev) => (prev === "\u00A0" ? char : prev + char)),
        timeoutTyping * (index + 1)
      );
    });
    // Add FVG one letter at a time
    fvg.split("").forEach((char, index) => {
      setTimeout(
        () => setBottomText((prev) => (prev === "\u00A0" ? char : prev + char)),
        timeoutTyping * (quidditch.length + index + 1)
      );
    });
    const timeForInitialTyping =
      timeoutTyping * (quidditch.length + fvg.length);

    // Apply red wavy underline to QUIDDITCH
    setTimeout(
      () => setUnderline(true),
      timeForInitialTyping + timeoutSpellcheck
    );

    // Delete letters from QUIDDITCH until QU is left
    quidditch
      .slice(2)
      .split("")
      .reverse()
      .forEach((_, index) => {
        setTimeout(
          () => setTopText((prev) => prev.slice(0, -1)),
          timeForInitialTyping +
            timeoutSpellcheck +
            timeoutBeforeActing +
            timeoutDeleting * index
        );
      });
    const timeForDeleting =
      timeForInitialTyping +
      timeoutSpellcheck +
      timeoutBeforeActing +
      timeoutDeleting * (quidditch.length - 2);

    // Add letters to form QUADBALL starting from QU
    const qu_adball = "ADBALL";
    qu_adball.split("").forEach((char, index) => {
      setTimeout(
        () => setTopText((prev) => prev + char),
        timeForDeleting + timeoutShortPause + timeoutTyping * index
      );
    });
    const timeForCorrecting =
      timeForDeleting + timeoutShortPause + timeoutTyping * qu_adball.length;

    // Remove red wavy underline from QUADBALL
    setTimeout(
      () => setUnderline(false),
      timeForCorrecting + timeoutSpellcheck
    );
    const timeForFinalTyping =
      timeForCorrecting + timeoutSpellcheck + timeoutShortPause;

    // Flash the whole title twice
    const flashDuration = 300;
    const flashPause = 200;
    const flashTimes = 2;

    for (let i = 0; i < flashTimes; i++) {
      setTimeout(() => {
        titleRef.current?.classList.add("title-flash");
      }, timeForFinalTyping + (flashDuration + flashPause) * i);

      setTimeout(() => {
        titleRef.current?.classList.remove("title-flash");
      }, timeForFinalTyping + flashDuration + (flashDuration + flashPause) * i);
    }
    const totAnimationTime =
      timeForFinalTyping +
      flashDuration +
      (flashDuration + flashPause) * flashTimes;

    // fail-safe
    setTimeout(() => {
      setTopText("QUADBALL");
      setBottomText("FVG");
    }, totAnimationTime + timeoutShortPause);
  }, []);

  return (
    <div ref={titleRef}>
      <div
        style={{
          textDecoration: underline ? "underline wavy #DD3333" : "none",
        }}
      >
        {topText}
      </div>
      <div>{bottomText}</div>
    </div>
  );
};

export default SiteTitle;
